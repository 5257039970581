/// auth service

export const LOGIN =
  "https://api.anytimehospital.com/serviceProvider/auth/login";

export const SIGNUP = "https://api.anytimehospital.com/user/auth/register";

export const CHANGE_PASSWORD_PHARMACY =
  "serviceProvider/auth/changePasswordPharmacy";

export const CHANGE_PASSWORD_LAB = "serviceProvider/auth/changePasswordLab";

export const GET_ORDER_LISTS = "serviceProvider/pharmacy/getOrderRequest";

export const GET_ORDER_DETAILS = "serviceProvider/pharmacy/orderById";

export const CREATE_INVOICE = "serviceProvider/pharmacy/actionOnOrder";

export const ADD_UPLOAD_REPORT = "lab/uploadReport";

export const EDIT_LAB_PROFILE = "serviceProvider/auth/editProfileLab"

export const EDIT_PHARMA_PROFILE = "serviceProvider/auth/editProfilePharmacy"

export const GET_LAB_PROFILE_DATA = "lab/labProfile"

export const GET_PHARMA_PROFILE_DATA = "serviceProvider/pharmacy/pharmacyProfile"

export const GET_LAB_ORDER_LISTS = "lab/orders";


export const GET_ORDER_LAB_DETAILS = "lab/orderDetail";

export const CREATE_LAB_INVOICE = "lab/actionOnOrder";


