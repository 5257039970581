import React, { useState, useRef, useEffect } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import { getInventoryModal } from "../../../services/AsyncFunctions";

function CreateInvoicePharma({ onHide, show, testData, setTestData }) {
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [inventoryData, setInvetoryData] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(true);

  async function submit() {
    onHide();
  }

  function addTestoInvoiceList(e) {
    e.preventDefault();
    if(selectedItem == null){
      toast.error("Select Medicine");
      return;
    }
    else if (!description) {
      toast.error("Enter description");
      return;
    } else if (quantity == "0") {
      toast.error("Enter quantity");
      return;
    } else if (!price) {
      toast.error("Enter price");
      return;
    } else {
      if (testData.length == 0) {
        setTestData([{ medicineName, description, quantity, price }]);
      } else {
        setTestData((prev) => [
          ...prev,
          { medicineName, description, quantity, price },
        ]);
      }
      setMedicineName("");
      setDescription("");
      setPrice("");
      setQuantity("1");
      setInvetoryData([]);
      setSelectedItem(null);
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = testData.filter((item, index) => ItemIndex !== index);
    setTestData([...tempList]);
  }

  // Filter items based on the search term
  const filteredItems = inventoryData.filter((item) =>
    item.medicineName.toLowerCase().includes(medicineName.toLowerCase())
  );

  // Handle input change
  const handleInputChange = (e) => {
    setMedicineName(e.target.value || "");
    if (!isOpen) setIsOpen(true);
  };

  // Handle item selection
  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setMedicineName(item.medicineName);
    setPrice(item.costPerUnit);
    setIsOpen(false);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  async function fetchInventory() {
    setLoading(true);
    try {
      const response = await getInventoryModal(medicineName,0);
      console.log(response);
      setInvetoryData(response.data.data.medicines);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(medicineName){
      const delayDebounceFn = setTimeout(() => {
        fetchInventory();
      }, 100);
     return () => clearTimeout(delayDebounceFn);
    }else {
      fetchInventory();
    }
  }, [medicineName]);

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create Invoice</h3>
              <Button variant="" className="close" onClick={() => onHide()}>
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addTestoInvoiceList}
              className="d-flex align-items-start flex-column gap-2"
            >
              <div
                ref={dropdownRef}
                className="relative mt-10"
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  value={medicineName}
                  onChange={handleInputChange}
                  onClick={() => setIsOpen(true)}
                  placeholder="Select Medicine"
                  className="form-control mr-2"
                />
                {isOpen && (
                  <ul className="absolute z-10 w-full bg-white border rounded shadow max-h-60 overflow-y-auto">
                    {inventoryData.length > 0 ? (
                      filteredItems.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectItem(item)}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          {item.medicineName}
                        </li>
                      ))
                    ) : (
                      <li className="px-4 py-2 text-gray-500">
                        No results found
                      </li>
                    )}
                  </ul>
                )}
                {selectedItem && (
                  <p className="mt-4 text-green-800">
                    You selected: {selectedItem.medicineName}
                  </p>
                )}
              </div>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-control mr-2"
                name="description"
              />
              <input
                type="text"
                placeholder="Medicine Name"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control mr-2"
                name="medicineName"
              />
              <input
                type="text"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="form-control mr-2"
                name="price"
                readOnly
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {testData.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Medicine Name
                  </div>
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Quantity
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Price
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Action
                  </div>
                </div>
                {testData.map((item, index) => (
                  <div key={index} className="row mt-3">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.medicineName}
                    </div>
                    <div className=" col-4 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.quantity}</span>
                    </div>
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-center"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button className="btn btn-primary w-100" onClick={submit}>
              Add Invoice
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateInvoicePharma;
