import React, { useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";

const iconColor = "#0cc0de";

const SideBar = () => {
  const loginType = localStorage.getItem("anyTimeHospitalType");
  const elRef = useRef(null);
  
  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
  
    const toggleFunc = () => aaa.classList.toggle("menu-toggle");
    btn?.addEventListener("click", toggleFunc);
  
    const dashboardElements = [
      ".dashboard",
      ".online-orders",
      ".offline-orders",
      ".lab-orders-online",
      ".lab-orders-offline",
      ".inventory",
      ".create-order",
      ".create-order-pharma"
    ];
  
    if (window.innerWidth < 1300) {
      dashboardElements.forEach(selector => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.addEventListener("click", toggleFunc);
        }
      });
    }
  
    return () => {
      btn?.removeEventListener("click", toggleFunc);
      dashboardElements.forEach(selector => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.removeEventListener("click", toggleFunc);
        }
      });
    };
  }, []);
  
  const path = window.location.pathname.split("/").pop();

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <div className="mm-wrapper">
          <ul className="metismenu" ref={elRef}>
          <li className={`dashboard ${path === "" ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/">
                <MdOutlineDashboard size={"30px"} color={iconColor} />
                <span className="nav-text pl-4">My Dashboard</span>
              </Link>
            </li>
            {loginType === "Pharmacy" && (
              <li className={`online-orders ${path === "online-orders" ? "mm-active" : ""}`}>
                <Link to="/online-orders" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Online Orders</span>
                </Link>
              </li>
            )}
            {loginType === "Pharmacy" && (
              <li className={`offline-orders ${path === "orders-offline" ? "mm-active" : ""}`}>
                <Link to="/offline-orders" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Offline Orders</span>
                </Link>
              </li>
            )}
            {loginType === "Lab" && (
              <li className={`lab-orders-online ${path === "lab-orders-online" ? "mm-active" : ""}`}>
                <Link to="/lab-orders-online" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Online Orders</span>
                </Link>
              </li>
            )}
            {loginType === "Lab" && (
              <li className={`lab-orders-offline ${path === "lab-orders-offline" ? "mm-active" : ""}`}>
                <Link to="/lab-orders-offline" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Offline Orders</span>
                </Link>
              </li>
            )}
            {loginType === "Pharmacy" && (
              <li className={`create-order ${path === "create-order-pharma" ? "mm-active" : ""}`}>
                <Link to="/create-order-pharma" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Create Order</span>
                </Link>
              </li>
            )}
            {loginType === "Lab" && (
              <li className={`create-order ${path === "create-order" ? "mm-active" : ""}`}>
                <Link to="/create-order" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Create Order</span>
                </Link>
              </li>
            )}
            {loginType === "Pharmacy" && (
              <li className={`inventory ${path === "inventory" ? "mm-active" : ""}`}>
                <Link to="/inventory" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Inventory</span>
                </Link>
              </li>)}
          </ul>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
