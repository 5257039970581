import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import toast from "react-hot-toast";
import { Nav, Spinner } from "react-bootstrap";
import { getOrders } from "../../services/AsyncFunctions";
import OrderCardOffline from "../components/cards/OrderCardOffline";

function OrderOffline() {
  const [currentCompletedPage, setCurrentCompletedPage] = useState(0);
  const [limit] = useState(10);
  const [hasMoreCompletedData, setHasMoreCompletedData] = useState(true);
  const [completedOrders, setCompletedOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function getCompletedOrders() {
    setIsLoading(true);
    try {
      let response = await getOrders(
        "completed",
        currentCompletedPage,
        "offline"
      );
      let updatedList = completedOrders
        ? [...completedOrders, ...response.data.data]
        : [...response.data.data];
      setCompletedOrders([...updatedList]);
      setHasMoreCompletedData(response.data.data.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function loadMoreAppointments(type) {
    if (type == "completed") {
      setCurrentCompletedPage((prev) => prev + 1);
    }
  }

  useEffect(() => {
    getCompletedOrders();
  }, [currentCompletedPage]);

  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Orders</h2>
        </div>
      </div>

      <div className="card-body px-0">
        <Tab.Pane eventKey="completed">
          {completedOrders &&
            completedOrders.map((item) => (
              <OrderCardOffline
                key={item._id}
                patientName={item?.user?.name}
                doctorName={item?.doctor?.name}
                medicines={item?.prescriptions}
                orderId={item?._id}
                orderMode={item?.method == "online" ? "Delivery" : "Pickup"}
                createdAt={item?.createdAt}
              />
            ))}
          {completedOrders && completedOrders.length == 0 && (
            <div className="text-center">
              <h3>No Data</h3>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" variant="info" />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {hasMoreCompletedData && !isLoading && (
              <button
                className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                onClick={() => loadMoreAppointments("completed")}
              >
                Load more
              </button>
            )}
          </div>
        </Tab.Pane>
      </div>
    </div>
  );
}

export default OrderOffline;
