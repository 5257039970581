import { z } from "zod";

export const zodValidationSchemaEditProfile = z.object({
  phoneNumber: z
    .number()
    .min(6, { message: "phone number must be at least 6 digits long" }),
    labName: z.string().trim().min(1, { message: "Name is required" }),
});

export const zodValidationSchemaEditProfilePharma = z.object({
  phoneNumber: z
    .number()
    .min(6, { message: "phone number must be at least 6 digits long" }),
    pharmacyName: z.string().trim().min(1, { message: "Name is required" }),
});